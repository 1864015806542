.main {
  height: 90vh;
  background-repeat: no-repeat;
  background-position: right 70px;
  background-size: cover;
  position: relative;

  @include mq(md) {
    margin-top: auto;
    height: auto;
    padding: 110px 0 40px;
    background-position: unset;
   }

  &__textBox {
    position: absolute;
    top: 55%;
    transform: translate(0, -50%);
    width: 50%;
    color: #144089;

    @include mq(xxl, min) {
      width: 40%;
      top: 50%;
    }

    @include mq(md) {
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
    }
    

    h1 {
      width: 90%;
      color: $black;
      margin-bottom: 25px;
      
      @include mq(md) {
        font-size: 32px;
       line-height: 36px;
      }
    }
  }

  &__rules {
    background-color: $secondary;
    padding: 40px 50px;
    color: $white;

    @include mq(md) {
      padding: 40px 20px;
    }

    .border-right {
      border-right: 1px solid rgba($white, .13);

      @include mq(md) {
        border-right: none;
        border-bottom: 1px solid rgba($white, .13);
      }
    }

    .text {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);

      .btn {
        background-color: #254383;
        color: $white;
      }
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      padding: 35px;
      text-align: center;

      p {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 0;
        min-height: 72px;

        @include mq(md) {
          min-height: 52px;
        }

        &:first-of-type {
          color: $primary;
          font-size: 76px;
          line-height: 71px;
        }
      }
    }
  }

  &__topText {
    font-size: 23px;
    font-weight: 400;
    color: $pink;
    width: fit-content;
    margin-bottom: 20px;

    span {
      font-weight: 600;
    }
  }

  &__desc {
    color: $black;
    font-size: 21px;
    line-height: 26px;
    width: 70%;

    @include mq(md) {
      width: 100%;
      font-size: 21px;
      line-height: 25px;
    }

    span {
      font-weight: 600;
    }

    strong {
      display: block;
    }
  }

  &__awards {
    width: 440px;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translate(0, -50%);

    @include mq(md) {
      width: 100%;
      position: unset;
    }
  }
  &__prizes{
    padding-bottom: 20px;
    display: flex;
    gap: 30px;
    align-items: center;
    margin-left: -10px;

    img {
      max-width: 280px;

      &:last-of-type {
        max-width: 250px;
      }
    }

    @include mq(md) {
      justify-content: space-between;

      img {
        width: 45%;
      }
    }
  }
  &__buttons {
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: -10px;

    @include mq(md) {
      margin-left: 0;
      gap: 10px;
      padding-top: 15px;
      flex-direction: column;
      align-items: flex-start;

      a {
        margin-bottom: 8px;
      }
    }
  }

  &__awardsMobile {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }

  &__products {
    position: relative;
    padding: 120px 0 50px;

    @include mq(md) {
      padding: 50px 0 20px;
    }

    .text {
      width: 65%;
      margin: 0 auto;
      text-align: center;
      z-index: 9999;

      @include mq(md) {
        width: 95%;
      }

      h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 700;

        @include mq(md) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      p {
        font-size: 18px;
      }

      .btn {
        background-color: #254383;
        margin: 0 auto;
        border-color: #254383;

        &:hover {
          background-color: transparent;
          color: #254383;
        }
      }
    }
  }
}

.productBox {
  margin-top: 130px;
  background-color: $white;
  border-radius: 20px;
  padding: 53px 53px 22px;

  @include mq(md) {
    margin-top: 70px;
    padding: 35px 35px 20px;
  }

  .top {
    display: flex;

    @include mq(md) {
      flex-direction: column;
    }

    .packshot {
      width: 60%;
      margin-left: auto;
      margin-top: -30px;
      margin-right: -40px;

      @include mq(md) {
        width: 100%;
        margin-left: unset;
        margin-top: 20px;
        margin-right: unset;
      }
    }
  }

  .bottom {
    .dawki {
      display: flex;
      align-items: center;
      gap: 15px;
      border-top: 2px solid #F8F8F8;
      padding-top: 20px;

      @include mq(md) {
        align-items: flex-start;

        flex-wrap: wrap;
        margin-right: 0;
        gap: 10px;
      }

      &--right {
        position: relative;
        left: 35%;

        @include mq(xxl, min) {
          left: 30%;
        }

        @include mq(md) {
          left: 0;
        }
      }

      p {
        font-size: 16px;
        padding-bottom: 0;
        margin-bottom: 0;

        &:not(.dawka) {
          color: #254383;
          font-weight: 400;
        }

        @include mq(md) {
          &:first-of-type {
            width: 100%;
          }
        }
      }

      .dawka {
        font-size: 13px;
        line-height: 15px;
        padding: 15px;
        border: 2px solid #EFEFF0;
        border-radius: 27px;
        color: $black;

        @include mq(md) {
          padding: 15px 20px;
        }
      }

      .spacer {
        width: 2px;
        height: 35px;
        background-color: #EFEFF0;
        margin: 0 50px;
        padding-top: 0;

        @include mq(md) {
          display: none;
        }
      }
    }
  }

  &__text {
    h2 {
      font-size: 40px;
      line-height: 44px;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }
}

.home {
  &__slider {
    margin-top: 50px;
    margin-bottom: 50px;

    @include mq(md) {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .splide__slide {
      @include mq(md) {
        width: 100%;
      }
    }

    .slider {
      &__items .splide__track {
        margin-right: calc((100vw - 100%)/-2);

        @include mq(md) {
          margin-right: unset;
        }
      }

      &__content {
        height: 100%;
        width: 500px;
        padding: 45px;
        position: relative;

        @include mq(md) {
          position: unset;
          width: 100%;
          height: 300px;
          padding: 30px;
        }

        h3 {
          font-family: 'Besley', sans-serif;
        }
      }

      &__text {
        color: $white;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        max-width: 420px;

        @include mq(md) {
          position: unset;
          top: unset;
          transform: unset;
        }

        h3 {
          margin-bottom: 15px;
          font-size: 38px;
          line-height: 48px;

          @include mq(md) {
            font-size: 28px;
            line-height: 38px;
          }
        }
      }

      &__desc {
        font-size: 20px;
        font-family: 'Jost', sans-serif;
        margin-bottom: 0;
        padding-right: 10px;

        @include mq(md) {
          font-size: 18px;
        }
      }

      &__img {
        height: 475px;
        object-fit: contain;
        transition: all .3s;

        &:hover {
          opacity: .85;
        }

        @include mq(xxl, min) {
          height: 550px;
        }

        &--content {
          object-fit: cover;
          width: 485px;

          &:hover {
            opacity: 1;
          }
        }

        @include mq(md) {
          width: auto;
          height: 100%;

          &--content {
            height: 300px;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      &__icon {
        width: auto;
        height: 55px;

        @include mq(md) {
          height: 45px;
          margin-bottom: 15px;
        }
      }
    }
    .splide__arrows {
      position: relative;
      left: 0;
      top: 50px;
      display: flex;
      gap: 20px;
      width: fit-content;

      @include mq(md) {
        top: 10px;
        justify-content: center;
      }
    }

    .splide__arrow {
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      width: 130px;
      height: 50px;
      border-radius: 24px;
      position: unset;
      opacity: 1;
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      display: flex;
      flex-direction: row;
      gap: 10px;

      &--next {
        right: -5em;
      }

      &--prev {
        left: 0;
        background-color: transparent;
        color: $primary;
      }
    }
  }

  &__rules-container{
    position: relative;
    padding: 50px;
    background-color: $white;
    color: $black;
    border-radius: 20px;

    @include mq(md) {
      padding: 30px 25px;
    }

    & .first_box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & img.doctor{
      position: absolute;
      width: 37%;
      right: 0;

      @media only screen and (min-width: 1400px) and (max-width: 4000px) {
        width: 28%;
      }
    }

    &--big {
      padding: 50px 30px 30px 38px;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: contain;

      @include mq(md) {
        background-image: unset !important;
      }

      @include mq(md) {
        padding: 35px 25px 25px 25px;
      }
    }

    &--video {
      background-size: 50%;
    }
  }

  &__rules {
    &--article {
      z-index: 1;
      background-size: contain;
      background-position: right center;
      background-repeat: no-repeat;

      .home__medicineContent div {
        padding-bottom: 0;
      }

      @include mq(md) {
        min-height: 750px;
        background-position: right bottom;
      }
    }
  }

  &__medicine{
    position: relative;

    h2{
      font-size: 2.37rem;
      line-height: 37px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 25px;
      font-family: "BloggerSans", sans-serif;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p{
      font-size: 1.5rem;
      line-height: 30px;
      padding-bottom: 40px;
      color: #9C9C9C;
    }
    ul{
      padding-bottom: 30px;
    }
    ul li{
      font-size: 1.3rem;
      line-height: 30px;
      color: #9C9C9C;
      list-style: none;
      margin-bottom: 17px;
    }
    ul li::before {
      content: "\2022";
      color: #E50C1B;
      font-weight: bold;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
    }
  }

  &__medicineLogo {
    margin-bottom: 35px;
    width: auto;
    height: 47px;
  }

  &__medicineImage {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 0 0 11px 11px;
  }

  &__medicineVideo {
    width: 90%;
    padding-bottom: 0 !important;
    text-align: left;
  }

  &__medicineContent {
    display: flex;
    position: relative;

    @include mq(md) {
      position: unset;
      flex-direction: column;
    }

    &--video {
      min-height: 440px;

      @include mq(md) {
        min-height: unset;
      }
    }

    &--right {
      img {
        &:first-of-type {
          margin-right: 100px;

          @include mq(md) {
            margin-right: 0;
          }
        }
      }

      .home {
        &__boxItems {
          @include mq(md) {
            margin-bottom: 15px;
          }
          .item {
            &:nth-child(2) {
              img {
                width: 28px;
                height: 28px;
              }
            }

            &:nth-child(1) {
              img {
                height: 40px;
              }
            }

            &:last-of-type {
              img {
                height: 30px;
              }
            }
          }
        }
      }
    }

    &--article {

      .home__boxHeader {
        color: $primary;
      }

      p {
        &:last-of-type {
          width: 50%;

          @include mq(md) {
            width: 100%;
          }
        }
      }
    }

    img {
      height: 260px;
      width: auto;
      object-position: center;
      object-fit: contain;

      @include mq(md) {
        height: 220px;
      }
    }

    .primary {
      color: $primary;
    }

    .secondary {
      color: $secondary;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 40px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      font-family: Poppins, sans-serif;
      color: $black;
      width: 70%;

      @include mq(xxl, min) {
        width: 55%;
      }

      @include mq(md) {
        width: 100%;
      }
    }
  }

  &__boxHeader {
    font-size: 20px !important;
    line-height: 26px !important;
    padding-bottom: 0 !important;
    margin-bottom: 17px !important;
    text-transform: uppercase;
  }

  &__boxItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 0 !important;

    .item {
      padding-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 17px;

      p {
        margin-bottom: 0;
        padding-bottom: 0;
        width: 100%;
      }

      img {
        height: 30px;
        width: auto;
        min-width: 22px;
        margin-right: 0;
      }

      &:nth-child(2) {
        img {
          width: 28px;
          height: 28px;
        }
      }

      &:last-of-type {
        img {
          height: 40px;
        }
      }
    }
  }

  &__medicineDawki {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 30px;
    padding-top: 25px;
    border-top: 1px solid #EFEFF0;

    @include mq(md) {
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: 0;
      gap: 10px;
    }

    &--right {
      position: relative;
      left: 35%;

      @include mq(xxl, min) {
        left: 30%;
      }

      @include mq(md) {
        left: 0;
      }
    }

    p {
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      color: $black;
      padding-bottom: 0;
      margin-bottom: 0;

      &:first-of-type {
        font-size: 20px;
      }

      @include mq(md) {
        &:first-of-type {
          width: 100%;
        }
      }
    }

    .dawka {
      font-size: 15px;
      line-height: 15px;
      padding: 15px 34px;
      border: 2px solid #EFEFF0;
      border-radius: 27px;

      @include mq(md) {
        padding: 15px 20px;
        margin-bottom: 7px;
      }
    }
  }

  &__rules {
    padding: 0 0 60px;
    position: relative;
    z-index: 999;

    &--first {
      margin-top: -40px;
    }
  }

  &_rule {
    height: 100%;
    position: relative;

    @include mq(md) {
      min-height: 100%;
      padding: 85px 0;
    }

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 23px;
      color: #fff;
      margin-bottom: 0;
      font-family: "BloggerSans", sans-serif;

      @include mq(md) {
        font-size: 20px;
      }
    }

    img {
      margin-bottom: 20px;
      width: 37px;
      height: auto;
      object-position: center;
      object-fit: contain;

      @include mq(md) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }

  &__ruleItems {
    display: flex;
    gap: 30px;

    @include mq(md) {
      flex-direction: column;
      gap: 15px;
      margin: 30px 0;
    }
  }

  &__ruleItem {
    padding: 23px;
    position: relative;
    background-color: $lightGreen;
    flex: 1 1 31%;
    height: 233px;
    border-radius: 10px;

    @include mq(md) {
      min-height: 200px;
      margin-bottom: 10px;
    }
  }

  &__rulesNumber {
    font-size: 125px;
    line-height: 120px;
    font-weight: 400;
    color: rgba($black, .05);
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 5px;
  }

  &__rulesButtons {
    margin-top: 20px;
  }

  &__banner {
    margin: 100px 0 70px;

    @include mq(md) {
      margin-top: 50px;
      padding-top: 50px;
    }

    .banner {
      background-image: url("../../dist/img/banner.webp");
      height: 620px;
      padding: 0 46px;
      color: $white;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include mq(md) {
        height: auto;
        padding: 30px;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
      }

      &__top {
        display: flex;
        align-items: flex-end;
        margin-bottom: 70px;

        img {
          width: 130px;
          height: auto;
          object-fit: contain;
          object-position: center;

          @include mq(md) {
            width: 90px;
          }
        }
      }

      &__content {
        width: 55%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        @include mq(md) {
          width: 100%;
          position: unset;
          top: unset;
          transform: unset;
        }

        h3 {
          margin-bottom: 0;
          width: 75%;
          font-size: 48px;
          font-family: 'Besley', sans-serif;

          @include mq(md) {
            font-size: 30px;
            width: 100%;
          }

          span {
            font-size: 30px;

            @include mq(md) {
              font-size: 24px;
            }
          }
        }

        p {
          color: rgba($white, .6);
          font-size: 19px;
          line-height: 25px;

          @include mq(md) {
            font-size: 17px;
            line-height: 23px;
            margin-bottom: 15px;
          }
        }

        ul {
          padding-left: 22px;
          color: rgba($white, .6);
          font-size: 19px;
          line-height: 25px;
          list-style: none;

          li::before {
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #FCE746; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }

          @include mq(md) {
            font-size: 17px;
            line-height: 23px;
          }
        }
      }

      &__app {
        position: absolute;
        top: 50%;
        right: 0;
        max-height: 550px;
        transform: translate(0, -50%);

        @include mq(md) {
          position: unset;
          transform: unset;
          max-height: 450px;
        }
      }

      &__download {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        gap: 15px;

        img {
          height: 55px;
          width: auto;

          @include mq(md) {
            height: 45px;
          }
        }
      }
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 660px;

    @include mq(md) {
      display: none;
    }
  }
}

.chmurka {
  position: absolute;
  z-index: -1;

  &--1 {
    width: 270px;
    top: 185px;
    animation-name: move1;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &--2 {
    width: 220px;
    top: 40px;
    right: -30px;
    animation-name: move2;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes move1 {
  0%   {left:0; top:185px;}
  25%  {left:60px; top:155px;}
  50%  {left:120px; top:185px;}
  75%  {left:60px; top:165px;}
  100% {left:0; top:185px;}
}

@keyframes move2 {
  0%   {right:-30px; top:40px;}
  25%  {right:20px; top:20px;}
  50%  {right:70px; top:40px;}
  75%  {right:20px; top:25px;}
  100% {right:-30px; top:40px;}
}

.balon {
  width: 225px;
  position: absolute;
  top: 290px;
  right: 0;
  animation-name: balon;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @include mq(md) {
    display: none;
  }
}

@keyframes balon {
  0%   {right:0; top:290px;}
  50%  {right:0; top:230px;}
  100% {right:0; top:290px;}
}