.footer {
    background-color: #144089;
    padding: 30px 0;
    position: relative;

    &:first-of-type {
        margin-top: 100px;

        @include mq(md) {
            margin-top: 10px;
        }
    }

    @include mq(md) {
        overflow: hidden;
        padding: 30px 0 20px;
    }

    &__wrapper {
        position: relative;
    }

    &__link {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: $primary;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 14px;
        color: rgba($white, .4);

        @include mq(md) {
            flex-direction: column;
        }

        a {
            color: rgba($white, .4);
            font-size: 14px;
            line-height: 14px;
        }

        p {
            margin-bottom: 0;
            font-size: 15px;
            line-height: 15px;
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        gap: 25px;
        align-items: center;
        margin-left: -42px;

        @include mq(md) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
        }

        a {
            @include mq(md) {
                margin-bottom: 7px;
            }
        }

        span {
            display: flex;
            align-items: center;
            gap: 15px;

            a {
                color: $white;
             }
        }
    }

    &__copyright {
        display: flex;
        align-items: center;
        background-color: #144089;
        color: $white;
        padding: 15px 0 20px;
    }

    &__brand {
        text-align: center;

        @include mq(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid rgba($white, .08);
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    &__ml {
        color: $white;
        font-size: 17px;
        margin-top: 25px;

        @include mq(md) {
            margin-top: 15px;
        }
    }

    &__contact {
        color: $white;
        font-size: 21px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mq(md) {
            margin-top: 20px;
            z-index: 9;
        }

        a {
            color: $secondary;
            font-size: 16px;
            
            &.btn{
                color: $black;
                background-color: #fff;
                border-radius: 29px;
                cursor: pointer;
            }
        }

        p {
            margin-bottom: 0;
        }

        img {
            margin-right: 15px;
        }

        &--first {
            padding-left: 40px;
            border-left: 1px solid rgba($white, .08);
            margin-left: 10px;

            @include mq(md) {
                padding-left: unset;
                border-left: none;
                margin-left: unset;
            }
        }

        &--last {
            padding-right: 40px;
            border-right: 1px solid rgba($white, .08);
            margin-right: 10px;

            @include mq(md) {
                padding-right: unset;
                border-right: none;
                margin-right: unset;
            }
        }
    }

    &__adamed {
        position: absolute;
        right: 20px;
        bottom: 0;
        z-index: 9;
        height: auto;
        width: 420px;

        @include mq(md) {
            display: none;
        }
    }

    &__sil {
        color: $black;
        font-size: 14px;
        line-height: 16px;
        max-width: 70%;

        @include mq(md) {
            max-width: 100%;
        }

        p {
            font-size: 10px;
            line-height: 13px;
            color: rgba($white, .4);
            margin-bottom: 0;
        }

        a {
            font-size: 15px;
            line-height: 20px;
            color: rgba($white, .4);
            text-decoration: underline;
        }
    }
}