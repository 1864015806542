html,
body {
    min-height:100%;
    margin: 0;
    padding: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: $font-family-base;
    overflow-x: hidden;
    background-color: #F8F8F8;
    position: relative;
}

.clear {
    &::before,
    &::after {
        content:' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

a,
button,
.btn {
    &:focus {
        box-shadow: none !important;
    }
}

.spacer {
    padding-top: 60px;
    border-bottom: 1px solid rgba($black, .15);
}

.page {
    position: relative;
    padding-top: 90px;
    min-height: 87vh;

    @include mq(md) {
        padding-top: 80px;
    }

    &__steps {
        width: 100%;
        text-align: center;
        margin: 20px 0 40px;
    }

    &--white {
        background: $white;
        height: 100%;
    }

    &--none {
        background: transparent;
    }

    &--presentation {
        margin-bottom: 0;

        @include mq(xxl, min) {
            margin-bottom: 50px;
        }

        @include mq(md) {
            margin-bottom: 30px;
        }
    }

    &--info {
        height: 100%;
        overflow: hidden;

        .home__background {
            bottom: -12%;

        }
    }

    &--quiz {
        height: 100%;
        overflow: hidden;

        .home__background {
            bottom: -30%;

            @include mq(xxl, min) {
                bottom: -48%;
            }
        }
    }

    &__textWhite {
        position: relative;
        z-index: 1;
        background-color: $white;
        padding: 40px 43px;
        border-radius: 20px;
        margin: 70px 0;
        color: $black;
        font-weight: 300;
        font-family: 'Poppins', sans-serif;

        @include mq(md) {
            padding: 25px;
        }

        h2 {
            color: $secondary;
            font-family: BloggerSans, sans-serif;
            margin-top: 47px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        a {
            color: $secondary;
            text-decoration: underline;
        }
    }

    .home__background {
        z-index: 0;
    }

    &__topImage {
        height: 380px;
        position: absolute;
        top: 40px;
        right: 0;
    }

    &__spacer {
        height: 55px;
        display: block;

        @include mq(md) {
            height: 25px;
        }
    }

    &__chplTitle {
        width: 50%;

        @include mq(md) {
            width: 100%;
        }
    }

    &__chplDesc {
        font-size: 29px;
        line-height: 30px;

        span {
            font-weight: 600;
            color: $pink;
        }

        @include mq(md) {
            font-size: 18px;
        }
    }

    &__znaczki {
        height: 132px;
        width: auto;
        object-fit: contain;
        margin-bottom: 30px;
    }

    &__operacyjny {
        height: 60px;
        width: auto;
        object-fit: contain;
        margin-bottom: 30px;
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-items: center;
        width: fit-content;
        margin: 0 auto;
        position: relative;
        z-index: 9;

        &:first-of-type {
            margin-top: 15px;

            @include mq(md) {
                margin-top: 25px;
            }
        }

        .step {
            display: flex;
            align-items: center;
            padding: 12px 14px 8px;
            border-radius: 50%;
            background: $primary;
            color: $white;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;

            @include mq(md) {
                font-size: 12px;
                line-height: 12px;
                padding: 10px 12px 7px;
            }

            &:first-of-type {
                padding: 12px 16px 8px 14px;

                @include mq(md) {
                    padding: 9px 12px 8px;
                }
            }

            &--inactive {
                color: #C0CED6;
                border: 2px solid #DBE8EF;
                background-color: $white;
            }
        }

        .line {
            width: 177px;
            height: 3px;
            background-color: #DBE8EF;

            @include mq(md) {
                width: 110px;
            }
        }

        &--text {
            width: 45%;
            justify-content: space-between;
            margin: 6px auto 50px;

            @include mq(xxl, min) {
                width: 38%;
            }

            @include mq(md) {
                width: 100%;
                margin: 5px auto 20px;
            }

            p {
                width: 100px;
                text-align: center;
                color: $primary;
                margin-bottom: 0;

                @include mq(md) {
                    font-size: 14px;
                }
            }

            .inactive {
                color: #C0CED6;
            }
        }
    }

    .accordion-item {
        margin-bottom: 10px !important;
        border: none !important;
        background-color: $third;
        border-radius: 15px !important;
        font-family: 'Poppins', sans-serif !important;

        h2 {
            line-height: 20px;
        }
    }

    .accordion-header,
    .accordion-button {
        background-color: $third;
        border-radius: 15px !important;
        border: none !important;
        font-family: 'Poppins', sans-serif !important;
        box-shadow: unset;
        font-weight: 600;
    }

    .accordion-body {
        padding-top: 0;
    }
}

.breadcrumbs {
    padding: 20px 0 50px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include mq(md) {
        gap: 5px;
    }

    a,
    p {
        color: rgba($black, .3);
        display: flex;
        gap: 10px;
        transition: all .3s;

        &:hover {
            color: $pink;
        }
    }

    .item {
        &:after {
            display: block;
            content: '>';
            width: 6px;
            height: 12px;
        }

        &:last-child {
            color: $pink;

            &:after {
                display: none;
            }
        }
    }
}

.modal-dialog {
    min-width: 700px;

    @include mq(md) {
        min-width: unset;
    }

    .modal-header {
        border-bottom: none;
    }

    .btn-close {
        background-color: $white;
        border: none;
        font-weight: 700;
    }
}